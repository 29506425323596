.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}