.editItemContainer {
    width: 100%;
}

.editItemContainer :first-child {
    width: 90%;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
