/* Apply your custom font to all Ant Design components */
.ant-typography,
.ant-btn,
.ant-input,
.ant-select,
.ant-checkbox,
.ant-radio,
.ant-switch,
.ant-table,
.ant-dropdown,
.ant-menu,
.ant-modal,
.ant-notification,
.ant-message,
.ant-tree,
.ant-image,
.ant-form {
  font-family: 'Diodrum-Regular';
  font-size: 16px;
}

.ant-input {
  color: #313131;
}

.ant-typography strong {
  font-family: 'Diodrum-Semibold';
}

/* .custom-menu .ant-menu-submenu-title .ant-menu-submenu-arrow {
  left: 10px;
  right: initial;
} */

.ant-menu-submenu-title {
  padding-left: 10px !important;
}

.ant-menu-item {
  padding-left: 20px !important;
}

.faqmenuList .ant-menu-item-group-title {
  padding: 0px !important;
}

.ant-layout-sider-zero-width-trigger {
  background-color: #313131 !important;
}

.formproductspec .ant-form-item-label label {
  color: #fff;
}


.firstform {
  margin-left: 15%;
  margin-bottom: 30px;
}

.secondform {
  width: 70%;
  margin-left: 15%;
  margin-bottom: 30px;
  border: 1px solid #BFBFBF;
  border-radius: 4px;
  padding: 20px;
}

.firstform .ant-form-item-label label {
  font-weight: bold;
}

.appnav {
  height: 30px !important;
  display: flex;
  align-items: center !important;
}

.appnav ::after {
  margin-bottom: 7px !important;
}

.ant-input-group-addon {
  border-radius: 0px;
  border: 0px !important;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}